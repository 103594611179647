import React from "react"

import {
    Text,
    useColorModeValue,
    SimpleGrid,
    Flex,
    Select,
    Button,
    List,
    ListItem
} from "@chakra-ui/react";

import AuthLink from "components/auth/AuthLink";

import moment from "moment";

import { MdAttachFile } from "react-icons/md";

import Information from "components/admin/Information";

import { MomentFormatWithTimeZone, MomentFormatDateOnly, LicenseStatusTextWithIcons, LicenseStatuses } from "utils/Constants";

function LicenseData(props) {
    const { licenseHistory, license, setLicense, ApproveLicenseClick } = props;

    return (<>
        <Flex gap="24px" mb="20px">
            <Select
                onChange={(e) => { setLicense(licenseHistory[e.target.value]) }}
            >
                {licenseHistory.map((data, i) => (
                    <option key={i} value={i}>
                        {i == 0 ? "(Current) " : ""} {moment(data.uploadedOn).format(MomentFormatDateOnly)} | Uploaded by - {data.uploaderName}
                    </option>
                ))}
            </Select>
            {((license.status != LicenseStatuses.Approved) && license == licenseHistory[0]) && (
                <Button onClick={() => ApproveLicenseClick(true)} rounded="md" minW="120px" colorScheme="brand">Approve</Button>
            )}

            {((license.status != LicenseStatuses.Rejected) && license == licenseHistory[0]) && (
                <Button onClick={() => ApproveLicenseClick(false)} rounded="md" minW="120px" colorScheme="red">Reject</Button>
            )}
        </Flex>
        <SimpleGrid columns={{ base: 1, md: 2, xl: 5 }} gap="20px" mb="20px">

            <Information
                title="Uploader"
                value={license.uploaderName}
            />
            <Information
                title="Status"
                value={LicenseStatusTextWithIcons[license.status]}
            />
            <Information
                title="Approver"
                value={license.approverName}
            />
            <Information
                title="Uploaded on"
                value={moment(license.uploadedOn).format(MomentFormatWithTimeZone)}
            />
            <Information
                title="Expiry Date"
                value={moment(license.expiryDate).format(MomentFormatDateOnly)}
            />

        </SimpleGrid>

        <Information
            title="License file"
            value={<AuthLink
                href={license.fileInfo.name}
                icon={MdAttachFile}
                color="blue.400"
                isExternal>
                {license.fileInfo.originalName}
            </AuthLink>}
        />
    </>);
}

export default function LicenseView(props) {
    const { license } = props;

    const textColor = useColorModeValue("secondaryGray.900", "white");

    return (<>
        <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%' mb="20px">
            License Information
        </Text>
        {license ? <LicenseData {...props} /> : <Information value="No license uploaded" />}
    </>);
}