import React from "react";

import {
    Box,
    Flex,
    Button,
} from "@chakra-ui/react";

import { NavLink } from "react-router-dom"

import Card from "components/card/Card";

import { MdArrowBackIosNew } from "react-icons/md";
import { useRouteMatch } from "react-router-dom"

import { Formik, Form } from 'formik';

export default function FormContainer(props) {
    const { params, url } = useRouteMatch();
    const { action } = params;
    const { children, initialValues, onSubmit, confirmText, cancelText, backUrl, hideActionButtons } = props;

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card
                direction='column'
                w='100%'
                px='0px'
                // overflowX={{ sm: "scroll", lg: "hidden" }}
                overflowX={{ sm: "scroll", lg: "visible" }}
                overflowY={{ sm: "scroll", lg: "visible" }}
            >
                <Flex px='25px' justify='space-between' mb='20px' align='center'>
                    <NavLink to={backUrl}>
                        <Button colorScheme="brand"><MdArrowBackIosNew /> Back</Button>
                    </NavLink>
                    {action === "view" && onSubmit && (
                        <NavLink to={url.replace("/view/", "/edit/")}>
                            <Button colorScheme="brand">Edit</Button>
                        </NavLink>
                    )}
                </Flex>

                <Flex px='24px' my="24px" flexDirection={"column"}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                    >
                        {(formProps) => (<Form>
                            {children}
                            {(action === "edit" || action === undefined) && (onSubmit) && !(hideActionButtons) && (
                                <Flex pt="24px" gap="24px" flexDirection={{ base: "column", md: "row" }} justify={"center"}>
                                    <Button type="submit" colorScheme="brand" isLoading={formProps.isSubmitting}>{confirmText}</Button>
                                    <Button type="button" colorScheme='brand' variant="outline" position="relative">
                                        <NavLink to={backUrl} style={{ position: "absolute", width: "100%", height: "100%" }} />
                                        {cancelText}
                                    </Button>
                                </Flex>
                            )}
                        </Form>)}
                    </Formik>
                </Flex>

            </Card>
        </Box>
    );
}


FormContainer.defaultProps = {
    confirmText: "Confirm",
    cancelText: "Cancel",
    hideActionButtons: false,
}