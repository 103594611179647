import {
  MdCheckCircle,
  MdCancel,
  MdOutlineError,
  MdDoDisturbOn,
  MdOutlinePending,
  MdLocalShipping,
  MdWarningAmber,
  MdOutlineAssignmentTurnedIn,
  MdOutlineReceiptLong,
} from "react-icons/md";

import { getImage, getFile } from "apis/files";

import { HStack, Icon, Text } from "@chakra-ui/react";

export const UserRoles = {
  Admin: 0,
  Customer: 1,
  ProductionStaff: 2,
  Driver: 3,
  SuperAdmin: 99
};

export const UserRoleNames = [
  "Admin",
  "Customer",
  "Production Staff",
  "Driver",
];

export const MomentFormatWithTimeZone = "DD MMMM, YYYY - hh:mm A (Z)";
export const MomentFormatDateOnly = "DD MMMM, YYYY";
export const MomentFormatDateOnlyForSubmit = "YYYY-MM-DD";

export const ImageUploadFileTypes = "image/jpeg,image/png,image/webp,image/gif";
export const PdfUploadFileType = "application/pdf";
// export const ImageAndPdfUploadFileTypes = `${ImageUploadFileTypes},${PdfUploadFileType}`;

export const MaxFilesUpload = 5;

export const OrderStatus = {
  Pending: 0,
  Confirmed: 1,
  ReadyForPickUp: 2,
  OnTheWay: 3,
  Delivered: 4,
  Delayed: 5,
  Cancelled: 6,
  AmendmentRequested: 7,
  Invoiced: 8
};

export const DaysOfWeekSelectOptions = [
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
  { value: 0, label: "Sunday" },
];

export const OrderStatusMsg = [
  "Pending",
  "Confirmed",
  "Ready For Pick-Up",
  "On The Way",
  "Delivered",
  "Delayed",
  "Cancelled",
  "Amendment Requested",
  "Invoiced",
];

export const OrderStatusIcon = [
  MdOutlinePending,
  MdOutlineError,
  MdOutlineAssignmentTurnedIn,
  MdLocalShipping,
  MdCheckCircle,
  MdDoDisturbOn,
  MdCancel,
  MdWarningAmber,
  MdOutlineReceiptLong,
];

export const OrderStatusColor = [
  "gray.500",
  "blue.400",
  "purple.400",
  "purple.400",
  "teal.500",
  "orange.500",
  "red.500",
  "red.500",
  "green.500",
];

export const OrderStatusOptions = OrderStatusMsg.map((msg, i) => {
  return {
    value: i.toString(),
    label: (
      <HStack>
        <Icon
          w="24px"
          h="24px"
          me="5px"
          color={OrderStatusColor[i]}
          as={OrderStatusIcon[i]}
        />
        <Text>{msg}</Text>
      </HStack>
    )
  }
})

export const DriverOrderStatusOptions = OrderStatusOptions.filter(o =>
  o.value == OrderStatus.OnTheWay ||
  o.value == OrderStatus.Delivered
)

export const OrderStatusSelectFilterOptions = (option, inputValue) => {
  const { value } = option;
  return OrderStatusMsg[value].toLowerCase().includes(inputValue.toLowerCase());
};

// License statuses
export const LicenseStatuses = {
  Pending: 0,
  Approved: 1,
  Rejected: 2,
  Expired: 3,
  NotUploaded: 4
}

export const LicenseStatusTextWithIcons = [
  (<HStack>
    <Icon
      w="24px"
      h="24px"
      color="gray.500"
      as={MdOutlinePending}
    />
    <Text>Pending</Text>
  </HStack>),
  (<HStack>
    <Icon
      w="24px"
      h="24px"
      color="green.500"
      as={MdCheckCircle}
    />
    <Text>Approved</Text>
  </HStack>),
  (<HStack>
    <Icon
      w="24px"
      h="24px"
      color="red.500"
      as={MdCancel}
    />
    <Text>Rejected</Text>
  </HStack>),
  (<HStack>
    <Icon
      w="24px"
      h="24px"
      color="red.500"
      as={MdCancel}
    />
    <Text>Expired</Text>
  </HStack>),
  (<HStack>
    <Icon
      w="24px"
      h="24px"
      color="red.500"
      as={MdCancel}
    />
    <Text>NotUploaded</Text>
  </HStack>),
]

export function getSelectOptionObj(item) {
  return { value: item.id, label: item.name };
}

export function validateEmpty(value) {
  let v = value;
  if (typeof value === "string") {
    v = value.trim();
  }
  return !v ? "This field cannot be empty" : null;
}

export function trimObject(obj) {

  const keys = Object.keys(obj)

  for (const key of keys) {
    const fieldType = typeof obj[key];
    switch (fieldType) {
      case "string":
        obj[key] = obj[key].trim()
        break;
      case "object":
        obj[key] = trimObject(obj[key])
        break;
      default:
        break;
    }
  }

  return obj
}

export async function getImageBlob(imageName) {
  try {
    let blob = await getImage(imageName);
    let url = URL.createObjectURL(blob);
    return url;
  } catch (error) {
    return null;
  }
}

export async function getFileBlob(fileName) {
  try {
    let blob = await getFile(fileName);
    let url = URL.createObjectURL(blob);
    return url;
  } catch (error) {
    return null;
  }
}