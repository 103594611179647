import { Flex, Box, Icon, Text, useColorModeValue } from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import moment from "moment";

import { columnsDataComplex } from "views/admin/order/components/columnsData";
import FilterBar from "views/admin/order/components/filterBar";
import ItemsTable from "components/admin/ItemsTable";

import { getOrderList } from "apis/order";

import {
  MomentFormatWithTimeZone,
  OrderStatusMsg,
  OrderStatusIcon,
  OrderStatusColor,
} from "utils/Constants";

export default function OrderList() {
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");

  function prepareData(data) {
    const preparedData = data.map((order) => {
      const [hour, min, sec] = order.deliveryTime.split(":");
      let deliveryDateTime = moment(order.deliveryDate).add({ hours: hour, minutes: min, seconds: sec })
      let requiredDateTime = moment(order.deliveryDate)
        .add({ hours: hour, minutes: min, seconds: sec })
        .format(MomentFormatWithTimeZone);
      let formattedOrderedDateTime = moment(order.orderedDateTime).format(
        MomentFormatWithTimeZone
      );
      order.requiredDateTime = (
        <Flex flexDirection="column" textAlign={{ base: "left", lg: "center" }}>
          <Text color={textColor}>{requiredDateTime}</Text>
          <Text textAlign={{ base: "left", lg: "center" }} color={moment(new Date()).diff(deliveryDateTime, 'hour') > 0 ? "red.500": "green.500"} fontSize='sm' fontWeight='700'>
            {moment(new Date()).diff(deliveryDateTime, 'hour') > 0 ? 
            `${moment(new Date()).to(deliveryDateTime)}`
            : 
            `${moment(deliveryDateTime).from(new Date())}`}
          </Text>
        </Flex>
      )

      order.formattedOrderedDateTime = (
        <Flex flexDirection="column" textAlign={{ base: "left", lg: "center" }}>
          <Text color={textColor}>{formattedOrderedDateTime}</Text>
          <Text textAlign={{ base: "left", lg: "center" }} color={moment(new Date()).diff(order.orderedDateTime, 'hour') > 0 ? "red.500": "green.500"} fontSize='sm' fontWeight='700'>
            {moment(new Date()).diff(order.orderedDateTime, 'hour') > 0 ? 
            `${moment(new Date()).to(order.orderedDateTime)}`
            : 
            `${moment(order.orderedDateTime).from(new Date())}`}
          </Text>
        </Flex>
      )
      order.statusDisplay = (
        <Flex justify={{ base: "left", lg: "center" }}>
          <Icon
            w="24px"
            h="24px"
            me="5px"
            color={OrderStatusColor[order.status]}
            as={OrderStatusIcon[order.status]}
          />
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {OrderStatusMsg[order.status]}
          </Text>
        </Flex>
      );
      return order;
    });
    setData(preparedData);
  }

  function refreshData() {
    getOrderList()
      .then((res) => {
        setUnfilteredData(res.data);
        prepareData(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  useEffect(() => {
    refreshData();
  }, []);

  return (
    <Box pt={{ base: "130px", lg: "80px", xl: "80px" }}>
      <FilterBar data={unfilteredData} setData={prepareData} />
      <ItemsTable
        columnsData={columnsDataComplex}
        tableData={data}
        viewUrl="order/view"
        createUrl="order/add"
        createText="Add"
      />
    </Box>
  );
}
